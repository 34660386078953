import { Input, Label, Select } from "@src/common/components";
import NewCustomerSuggestionInput from "../NewCustomerSuggestionInput";
import "react-datepicker/dist/react-datepicker.css";
import CostCenterSelector from "../CostCenterSelector";
import {
  CustomRecurrenceScheduleType,
  ExtendedRecurrenceScheduleType,
  useCreateOrderContext,
} from "../../utils/CreateOrderContextProvider";
import StartDateEndDateSelector from "../StartDateEndDateSelector";
import ScheduleTypeSelector from "../ScheduleTypeSelector";
import classNames from "classnames";
import SelectOrderRecurrence from "../SelectOrderRecurrence";
import { Controller, useFormContext } from "react-hook-form";

export enum CustomerAction {
  PickUpAtCustomer = "Pick Up",
  DropOffAtCustomer = "Drop Off",
  None = "None",
}

type Props = {};

export default function GeneralForm({}: Props) {
  const {
    autoFillTypeValue,
    setAutoFillTypeValue,
    selectedCustomer,
    chosenCostCenter,
    recurrenceSchedule,
    setRecurrenceSchedule,
    scheduleDates,
    setScheduleDates,
    selectedDaysWeek,
    setSelectedDaysWeek,
  } = useCreateOrderContext();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { selectedCustomDaysOfTheMonth, setSelectedCustomDaysOfTheMonth } =
    useCreateOrderContext();

  const handleChange = (params) => {
    // Ensure that the value is always of type ExtendedRecurrenceScheduleType
    const newValue = params.value[0]?.id;
    if (newValue) {
      setRecurrenceSchedule(newValue as ExtendedRecurrenceScheduleType);
    }
  };

  return (
    <div className="grid gap-4 grid-cols-12 p-4">
      <div className="col-span-6 flex gap-1">
        <div className="flex-1 flex flex-col ">
          <SelectOrderRecurrence
            recurrenceSchedule={recurrenceSchedule}
            handleChange={handleChange}
          />
        </div>
        {recurrenceSchedule !== CustomRecurrenceScheduleType.DoesNotRepeat && (
          <div className="flex-1 flex flex-col">
            <Label className="text-xs">
              Name
              <span className="text-xs text-slate-400 ml-2">(Optional)</span>
            </Label>
            <Controller
              control={control}
              name={"name"}
              render={({ field }) => (
                <Input
                  size={"mini"}
                  value={field.value}
                  onChange={(event) =>
                    field.onChange(event.currentTarget.value)
                  }
                />
              )}
            />
          </div>
        )}
      </div>
      {recurrenceSchedule !== CustomRecurrenceScheduleType.DoesNotRepeat && (
        <div className="col-span-6">
          <StartDateEndDateSelector
            scheduleDates={scheduleDates}
            setScheduleDates={setScheduleDates}
            recurrenceSchedule={recurrenceSchedule}
          />
        </div>
      )}

      {
        <div
          className={classNames({
            "col-span-6":
              recurrenceSchedule === CustomRecurrenceScheduleType.DoesNotRepeat,
            "col-span-12":
              recurrenceSchedule !== CustomRecurrenceScheduleType.DoesNotRepeat,
          })}
        >
          <ScheduleTypeSelector
            control={control}
            errors={errors}
            recurrenceSchedule={recurrenceSchedule}
            selectedDaysWeek={selectedDaysWeek}
            setSelectedDaysWeek={setSelectedDaysWeek}
            selectedCustomDaysOfTheMonth={selectedCustomDaysOfTheMonth}
            setSelectedCustomDaysOfTheMonth={setSelectedCustomDaysOfTheMonth}
          />
        </div>
      }
      <div className="col-span-6">
        <NewCustomerSuggestionInput />
      </div>
      <div className="col-span-4">
        <CostCenterSelector />
      </div>
      <div className="relative col-span-2 flex flex-col">
        <Label className="truncate text-xs">
          Autofill <span className="text-slate-400 text-xs">(Optional)</span>
        </Label>
        <Select
          size="mini"
          disabled={selectedCustomer || chosenCostCenter ? false : true}
          backspaceRemoves={false}
          clearable={false}
          deleteRemoves={false}
          escapeClearsValue={false}
          options={[
            { label: CustomerAction.PickUpAtCustomer, id: "1" },
            { label: CustomerAction.DropOffAtCustomer, id: "2" },
            { label: CustomerAction.None, id: "3" },
          ]}
          value={autoFillTypeValue}
          searchable={false}
          placeholder={""}
          onChange={(params: any) =>
            setAutoFillTypeValue(
              params.value as [{ label: CustomerAction; id: string }]
            )
          }
        />
      </div>
    </div>
  );
}
