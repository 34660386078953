import React, { useContext, useRef } from "react";
import { Label } from "flowbite-react";
import {
  EndCustomer,
  useSearchEndCustomerLazyQuery,
} from "@api/graphql/generated/generated-types";
import { useEffect } from "react";
import { useDebounce } from "usehooks-ts";
import { Controller, useFormContext } from "react-hook-form";
import { Combobox } from "@src/common/components";
import { AuthContext } from "@src/auth/components/AuthProvider";
import {
  EndCustomerDisplay,
  makeEndCustomerDisplay,
} from "@src/endcustomers/utils/endCustomerDisplayer";
import { useCreateOrderContext } from "../utils/CreateOrderContextProvider";
import { LoadingSpinner } from "baseui/button/styled-components";

type Props = {};

function NewCustomerSuggestionInput({}: Props) {
  const {
    setisNewCustomerFormOpen,
    selectedCustomer,
    setSelectedCustomer,
    query,
    setQuery,
  } = useCreateOrderContext();
  const inputRef = useRef<HTMLElement>(null);
  const [options, setOptions] = React.useState<EndCustomer[]>([]);
  const { setValue, control, clearErrors } = useFormContext();
  const debouncedQueryValue = useDebounce<string>(query, 500);
  const [searchEndCustomerLazyQuery, { data: endCustomersData, loading }] =
    useSearchEndCustomerLazyQuery();

  const { courierId } = useContext(AuthContext);

  useEffect(() => {
    if (selectedCustomer) {
      setQuery(selectedCustomer.companyName || "");
    }
    return () => {
      setQuery("");
    };
  }, [selectedCustomer]);

  useEffect(() => {
    const endCustomers: EndCustomer[] =
      (endCustomersData?.searchEndCustomer as EndCustomer[]) || [];
    setOptions(endCustomers);
  }, [endCustomersData]);

  useEffect(() => {
    if (debouncedQueryValue.length > 0) {
      searchEndCustomerLazyQuery({
        variables: {
          courierId: courierId!,
          searchTerm: debouncedQueryValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQueryValue]);

  const handleChangeCustomer = (
    nextValue: string,
    customer?: EndCustomer | null
  ) => {
    setQuery(nextValue);
    if (customer) {
      setValue("endCustomerId", customer.id);
      setSelectedCustomer(customer);
      clearErrors("endCustomerId");
    } else {
      setValue("endCustomerId", "");
      setSelectedCustomer(undefined);
    }
  };

  function mapOptionToString(option: EndCustomer): string {
    if (option.companyName && option.id === selectedCustomer?.id) {
      return option.companyName;
    }
    return "";
  }

  return (
    <>
      <div className="flex flex-col justify-between">
        <Label className="text-xs font-medium text-gray-700  flex justify-between">
          <div className="flex">
            Customer{" "}
            <span className="text-orange-400 text-xs"> (Required)</span>
            <span className="ml-2">
              <LoadingSpinner
                $style={{ display: loading ? "block" : "none" }}
              />
            </span>
          </div>
          <span
            className="text-xs hover:underline hover:cursor-pointer text-blue-500"
            onClick={() => setisNewCustomerFormOpen(true)}
          >
            Create New Customer
          </span>
        </Label>
        <Controller
          name={`endCustomerId`}
          control={control}
          rules={{ required: true }}
          render={({ fieldState: { error }, field: { ref: callbackRef } }) => {
            callbackRef(inputRef.current);
            return (
              <Combobox
                size="mini"
                inputRef={inputRef}
                error={error ? true : false}
                onChange={(b, a) => {
                  handleChangeCustomer(b, a as EndCustomer);
                }}
                mapOptionToString={(a) => mapOptionToString(a as EndCustomer)}
                value={query}
                name="customers-options"
                options={options}
                mapOptionToNode={({ option }) => (
                  <EndCustomerSuggestionDisplay
                    endCustomer={makeEndCustomerDisplay(option as EndCustomer)}
                  />
                )}
                overrides={{
                  Input: {
                    props: {
                      placeholder:
                        "Search by Company, First name, Last Name, Email, Phone, or ID",
                      error: error ? true : false,
                      autoComplete: "off",
                    },
                  },
                  // Adjust the following override according to the actual structure and class names
                  ListItem: {
                    style: {
                      paddingTop: "5px", // Increase padding for larger items, if necessary
                      paddingBottom: "5px",
                      height: "auto", // Set height to auto, if necessary
                      // Any other styles you want to apply
                    },
                  },
                }}
              />
            );
          }}
        />
      </div>
    </>
  );
}

export function EndCustomerSuggestionDisplay({
  endCustomer,
}: {
  endCustomer: EndCustomerDisplay;
}) {
  return (
    <div className="flex items-center gap-2 hover:cursor-pointer w-full">
      <div
        className="text-sm rounded-full bg-slate-50 justify-center items-center flex my-auto"
        style={{
          width: 30,
          height: 30,
          minWidth: 30,
        }}
      >
        {endCustomer.idDisplay}
      </div>
      <div className="flex flex-col">
        <div className="text-sm font-semibold">{endCustomer.title}</div>

        <div className="text-sm text-slate-600">{endCustomer.subTitle}</div>
      </div>
    </div>
  );
}

export default NewCustomerSuggestionInput;
